import { GetStaticProps } from 'next';

import { getCopy } from 'store/copy.data';
import { PageProps } from 'types/page';
import { NonModulesPages } from 'utils/routes';

export const getStaticProps: GetStaticProps = async ctx => {
  const initialLocale = ctx.locale || 'en-gb';
  const initialCopy = getCopy(NonModulesPages.notFound, initialLocale);

  const props: PageProps = {
    initialLocale,
    initialCopy,
    authData: null,
    currentPage: NonModulesPages.notFound,
    dehydratedState: null,
  };

  return {
    props,
  };
};

export default function page404() {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          fontFamily: 'Arial',
          flexDirection: 'column',
          width: '50vw',
          margin: '0 auto',
        }}
      >
        <h2>404 Page Not Found</h2>
        <h3>
          The page you are looking for is not available. It might have been
          removed.
        </h3>
        <h4>
          If you are trying to access a project, ensure you have the necessary
          permissions. Check the Projects List Page to see if the project is
          listed. If you cannot find the project, you likely do not have access.
          Please contact an administrator to request access to the project.
        </h4>
      </div>
    </>
  );
}
